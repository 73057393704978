const validateFormat = (conversionLogic, notifyError, notifySuccess) => {
    if (!conversionLogic.formula) {
        notifyError('Formula is required.');
        return false;
    }

    const fieldIds = conversionLogic.fields.map((f) => f.field_id.trim());

    // Ensure no duplicate field IDs
    const uniqueFieldIds = new Set(fieldIds);
    if (uniqueFieldIds.size !== fieldIds.length) {
        notifyError('Field IDs must be unique.');
        return false;
    }

    // Check if all fields have valid values
    for (const field of conversionLogic.fields) {
        if (!field.field_id.trim() || !field.label.trim()) {
            notifyError('Field ID and label are required.');
            return false;
        }
    }

    // Validate formula syntax (basic validation using regex for variable names)
    const { formula } = conversionLogic;
    const formulaVariables = formula.match(/[a-zA-Z_][a-zA-Z0-9_]*/g) || [];

    for (const variable of formulaVariables) {
        if (!uniqueFieldIds.has(variable)) {
            notifyError(`Field ID "${variable}" is not defined in the fields.`);
            return false;
        }
    }

    // Parentheses Validation
    const isValidParentheses = (expression) => {
        const stack = [];
        for (const char of expression) {
            if (char === '(') {
                stack.push(char);
            } else if (char === ')') {
                if (stack.length === 0) {
                    return false; // More closing than opening brackets
                }
                stack.pop();
            }
        }
        return stack.length === 0; // If stack is empty, parentheses are balanced
    };

    if (!isValidParentheses(formula)) {
        notifyError('Parentheses are not balanced.');
        return false;
    }

    // Attempt to evaluate the formula safely
    try {
        // Replace variables with dummy values (1) for testing
        const testFormula = formulaVariables.reduce(
            (acc, variable) => acc.replace(new RegExp(`\\b${variable}\\b`, 'g'), '1'),
            formula
        );

        // eslint-disable-next-line no-new-func
        const result = new Function(`return (${testFormula});`)(); // Attempt to execute

        if (typeof result !== 'number' || Number.isNaN(result)) {
            notifyError('Formula does not return a valid number.');
            return false;
        }
    } catch (error) {
        notifyError('Invalid formula syntax.');
        return false;
    }

    notifySuccess('Formula syntax is valid.');
    return true;
};

export default validateFormat;
