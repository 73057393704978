import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import useSorting from '../../../hooks/useSorting';
import { PERMISSIONS, TABLE_ROW_COUNT_DEFAULT } from '../../../helpers/constans';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall, useApi } from '../../../hooks/useApi';
import DatalinesTable from '../components/DatalinesTable';
import PageWithHeader from '../../../components/header/PageWithHeader';

const IncorrectDatalinesPage = () => {
    const { userPermissions } = useAuth();
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const { showModal } = useModals();
    const [selectMode, setSelectMode] = useState(false);
    const [selectedDatalines, setSelectedDatalines] = useState([]);

    const { scope, category, subcategory, emissionSource, site, year, edited, setEdited } =
        useGlobalFilters();
    const { notifyError, notifySuccess } = useNotify();
    const manageDatalinePermission = userPermissions.includes(PERMISSIONS.MANAGE_DATALINES);
    const basicModifyDatalinePermission = userPermissions.includes(
        PERMISSIONS.BASIC_MODIFY_DATALINES
    );
    const complexModifyDatalinePermission = userPermissions.includes(
        PERMISSIONS.COMPLEX_MODIFY_DATALINES
    );

    const [data, loading, error, setData, reloadData] = useApi('Datalines', 'getDatalines', {
        year,
        scope,
        categoryId: category,
        subcategoryId: subcategory,
        emissionSourceId: emissionSource,
        limit,
        offset: page * limit,
        sort,
        sortDir,
        siteIdList: site,
        incorrect: true,
    });

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    const deleteDatalinesList = () => {
        showModal(MODAL_TYPE.CONFIRMATION, {
            title: `Are you sure you want to remove datalines?`,
            content: 'This action is irreversible.',
            confirm: () => {
                doSwaggerCall('Datalines', 'deleteDatalinesByList', {
                    idList: selectedDatalines.map((s) => s.id),
                })
                    .then(() => {
                        notifySuccess('Datalines deleted');
                        reloadData();
                        setSelectedDatalines([]);
                    })
                    .catch((err) => {
                        notifyError(err);
                    });
            },
        });
    };

    return (
        <PageWithHeader title="Incorrect GHG Data">
            <DatalinesTable
                rows={data?.datalines}
                limit={limit}
                page={page}
                setParams={setParams}
                total={data?.total}
                loading={loading}
                reloadData={reloadData}
                sort={sort}
                sites={site}
                sortDir={sortDir}
                setSorting={setSorting}
                edited={edited}
                setEdited={setEdited}
                selectMode={selectMode}
                setSelectMode={setSelectMode}
                selectedDatalines={selectedDatalines}
                setSelectedDatalines={setSelectedDatalines}
                deleteDatalinesList={deleteDatalinesList}
                emissionSourceId={emissionSource}
                manageDatalinePermission={manageDatalinePermission}
                basicModifyDatalinePermission={basicModifyDatalinePermission}
                complexModifyDatalinePermission={complexModifyDatalinePermission}
                setGroupOpen={() => {}}
            />
        </PageWithHeader>
    );
};

export default IncorrectDatalinesPage;
