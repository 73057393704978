import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { esgDisclosureAutocomplete } from '../autocomplete';
import AutocompleteSelect from '../components/atom/AutoCompleteSelect';
import { doSwaggerCall } from '../hooks/useApi';
import useNotify from '../hooks/useNotify';
import CommonButton from '../components/atom/CommonButton';

function AddEsgQuestionModal({ showModal, MODAL_TYPE }) {
    const { notifySuccess, notifyError } = useNotify();
    const navigate = useNavigate();

    const onSubmit = async (values) => {
        try {
            const res = await doSwaggerCall(
                'EsgQuestions',
                'addEsgQuestion',
                {},
                {
                    name: values.name.trim(),
                    esgDisclosureId: values.esg_disclosure_id
                        ? parseInt(values.esg_disclosure_id, 10)
                        : null,
                    fields: [],
                    guideNote: null,
                }
            );
            navigate(`/esgquestions/edit/${res.id}`);
            notifySuccess('New ESG question created!');
            showModal(MODAL_TYPE.NONE);
        } catch (error) {
            notifyError(error);
        }
    };

    const validateSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        // esg_disclosure_id will be required in case of add, but not in case of edit
        esg_disclosure_id: Yup.string().required('Required'),
    });

    return (
        <Dialog
            aria-labelledby="add-esg-question-modal"
            open
            maxWidth="sm"
            fullWidth
            onClose={() => showModal(MODAL_TYPE.NONE)}
        >
            <DialogTitle>Add ESG question</DialogTitle>
            <Formik
                onSubmit={onSubmit}
                initialValues={{
                    name: '',
                    esg_disclosure_id: '',
                }}
                validationSchema={validateSchema}
            >
                {({ isSubmitting, values, touched, errors, setFieldValue, isValid, dirty }) => (
                    <Form>
                        <Grid>
                            <DialogContent>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <AutocompleteSelect
                                            initCall={esgDisclosureAutocomplete.init}
                                            autocompleteCall={esgDisclosureAutocomplete.search}
                                            value={values.esg_disclosure_id}
                                            setValue={(ev) =>
                                                setFieldValue('esg_disclosure_id', ev)
                                            }
                                            // esg_disclosure_id will be required in case of add, but not in case of edit
                                            error={
                                                touched.esg_disclosure_id &&
                                                Boolean(errors.esg_disclosure_id)
                                            }
                                            helperText={
                                                touched.esg_disclosure_id
                                                    ? errors.esg_disclosure_id
                                                    : ''
                                            }
                                        >
                                            <TextField label="ESG Subsubcategory" required />
                                        </AutocompleteSelect>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="ESG question name"
                                            type="text"
                                            autoComplete="off"
                                            value={values.name}
                                            onChange={(ev) =>
                                                setFieldValue('name', ev.target.value)
                                            }
                                            error={touched.name && Boolean(errors.name)}
                                            helperText={touched.name ? errors.name : ''}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Grid>
                        <DialogActions>
                            <CommonButton
                                variant="inherit"
                                onClick={() => showModal(MODAL_TYPE.NONE)}
                                label="Close"
                                buttonType="secondary"
                            />
                            <CommonButton
                                disabled={isSubmitting || !(isValid && dirty)}
                                type="submit"
                                label="Submit"
                                buttonType="primary"
                            />
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}

export default AddEsgQuestionModal;
