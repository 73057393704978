import { Grid, Container } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import React, { useEffect } from 'react';
import { LegendsContextProvider, useLegends } from '../../../../hooks/useLegends';
import { useDarkMode } from '../../../../hooks/useDarkMode';
import {
    BARCHART_PROPS,
    customAxisColor,
    customNivoTheme,
} from '../../../../helpers/chartStyleHelper';
import { formatNumber } from '../../../../helpers/convertUnits';
import getPaperBottom from '../helpers/getPaperBottom';
import legendAlign from './legendAlignHelper';

const BarLegendSink = ({ legendData }) => {
    const { setLegends } = useLegends();

    useEffect(() => {
        setLegends(legendData[0][1]);
    }, [setLegends, legendData]);
    return null;
};

const LegendDisplay = () => {
    const { legends } = useLegends();

    let itemHeight = 20;
    let symbolSize = 20;
    let marginSize = ' -3px';
    if (legends.length > 5) {
        itemHeight = 10;
        symbolSize = 10;
        marginSize = '0px';
    }

    return legends.map((e) => {
        return (
            <React.Fragment key={e.id}>
                <Container
                    maxWidth="xl"
                    sx={{
                        display: 'inline-block',
                        whiteSpace: 'nowrap',
                        ...legendAlign(legends).center.container,
                    }}
                >
                    <svg
                        style={{
                            backgroundColor: e.color,
                            width: symbolSize,
                            height: itemHeight,
                            marginRight: '5px',
                            marginBottom: marginSize,
                            ...legendAlign(legends).center.label,
                        }}
                    />
                    <Grid display="inline" xs={12} spacing={0}>
                        {e.label}
                    </Grid>
                </Container>
            </React.Fragment>
        );
    });
};

const BarChart = ({ data, keys, axisLeftLegend, format, label, isPreview = false }) => {
    const { darkMode } = useDarkMode();

    // set paper bottom margin
    const lengthConditions = [21, 18, 15, 12, 9, 6, 3];
    const paperBottomValues = ['18vh', '16vh', '14vh', '12vh', '10vh', '8vh', '6vh', '4vh'];
    const paperBottom = getPaperBottom(data, lengthConditions, paperBottomValues);

    const formatAxis = (value) => {
        let response = value;
        // trimStart for remove starting space
        if (value[0] === ' ') {
            response = value.trimStart();
        }
        if (response.length > 8) {
            response = `${response.substring(0, 8)}...`;
        }
        if (data.length > 10) {
            response = '';
            const words = value.trimStart().split(' ');
            for (let i = 0; i < words.length - 2; i++) {
                response += Number.isNaN(parseFloat(words[i])) ? `${words[i][0]}` : `${words[i]}`;
            }
            if (response.charAt(response.length - 1) === '(') {
                response = response.slice(0, -1);
            }
        }
        return response;
    };
    const formatLegend = (value) => {
        let response = value;
        if (value.length > 35) {
            response = `${value.substring(0, 35)}...`;
        }
        return response;
    };

    return (
        <Grid
            item
            xs={12}
            style={{
                height: '40vh',
                marginBottom: paperBottom,
                marginRight: '-25px',
                marginLeft: '5px',
            }}
        >
            <LegendsContextProvider>
                <ResponsiveBar
                    data={data}
                    theme={customNivoTheme(darkMode)}
                    colors={BARCHART_PROPS.colors}
                    enableLabel={BARCHART_PROPS.enableLabel}
                    borderRadius={BARCHART_PROPS.borderRadius}
                    borderWidth={BARCHART_PROPS.borderWidth}
                    labelSkipHeight={BARCHART_PROPS.labelSkipHeight}
                    borderColor={BARCHART_PROPS.borderColor}
                    labelTextColor={BARCHART_PROPS.labelTextColor}
                    padding={BARCHART_PROPS.padding}
                    labelSkipWidth={BARCHART_PROPS.labelSkipWidth}
                    valueFormat={BARCHART_PROPS.valueFormat}
                    keys={keys}
                    colorBy="index"
                    indexBy="category"
                    margin={{ right: 60, bottom: 60, left: 80, top: 20 }}
                    label={label}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        format: (value) => {
                            return formatAxis(value);
                        },
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: axisLeftLegend,
                        legendPosition: 'middle',
                        legendOffset: -75,
                        format,
                        renderTick: (props) => {
                            return (
                                <text
                                    style={{
                                        fontWeight: 'bold',
                                    }}
                                    textAnchor="end"
                                    fill={customAxisColor(darkMode)}
                                    fontSize={12}
                                    x={props.x - 4}
                                    y={props.y + 4}
                                >
                                    {formatNumber(props.value)}
                                </text>
                            );
                        },
                    }}
                    legendLabel={(value) => formatLegend(value.indexValue)}
                    layers={['grid', 'axes', 'bars', 'markers', BarLegendSink]}
                    legends={[
                        {
                            dataFrom: 'indexes',
                        },
                    ]}
                    valueScale={{ type: 'linear' }}
                />
                {!isPreview && <LegendDisplay />}
            </LegendsContextProvider>
        </Grid>
    );
};

export default BarChart;
