import React, { useCallback, useState, useEffect } from 'react';
import {
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Dialog,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Paper,
    Tooltip,
    IconButton,
    Typography,
} from '@mui/material';
import SortableTableCell from '../components/atom/SortableTableCell';
import useSorting from '../hooks/useSorting';
import { checkResultForRow, iconByType } from '../helpers/modalHelper';
import CommonButton from '../components/atom/CommonButton';
import ImportResultsHeader from './components/ImportResultsHeader';

function ImportFrameworkQuestionTableRow({ row }) {
    return (
        <TableRow key={row.id}>
            <TableCell align="left" width="10%">
                <IconButton disabled>{iconByType(row.esgType)}</IconButton>
                {row.esgType}
            </TableCell>
            <TableCell align="left" width="5%">
                {row.id}
            </TableCell>
            <TableCell align="left" width="20%">
                {row.name}
            </TableCell>
            <TableCell align="left" width="20%">
                {row.question}
            </TableCell>
            <TableCell align="left" width="20%">
                {row.fields.length > 0
                    ? row.fields.map((field) =>
                          field.defaultMeasure ? (
                              <p>
                                  {field.label} (default measure: {field.defaultMeasure})
                              </p>
                          ) : (
                              <p>{field.label}</p>
                          )
                      )
                    : `-`}
            </TableCell>
            <Tooltip title={row.guidance} placement="right">
                <TableCell align="left" width="15%">
                    {row.guidance ? `${row.guidance?.slice(0, 60)} ...` : ''}
                </TableCell>
            </Tooltip>
            <TableCell align="left" width="10%">
                {checkResultForRow(row)}
            </TableCell>
        </TableRow>
    );
}

const ConfirmationImportEsgFrameworkQuestionsModal = ({
    title,
    content,
    data,
    confirm,
    close = false,
    showModal,
    setImportingXlsx,
    MODAL_TYPE,
}) => {
    const closeAction = useCallback(
        close ||
            (() => {
                showModal(MODAL_TYPE.NONE);
                setImportingXlsx(false);
            }),
        [close]
    );
    const [showCorrectFrameworkQuestions, setShowCorrectFrameworkQuestions] = useState(false);
    const allFrameworkQuestionData = [...data.toCreate, ...data.toUpdate, ...data.toDelete];
    const correctFrameworkQuestionsLength = allFrameworkQuestionData.filter(
        (row) => !row.error && !row.markedForDeletion
    ).length;
    const { sort, sortDir, setSorting } = useSorting(['result', 'ASC']);
    const [sortedFrameworkQuestions, setSortedFrameworkQuestions] = useState([]);

    useEffect(() => {
        setSortedFrameworkQuestions([
            ...allFrameworkQuestionData.sort(
                sortDir === 'DESC'
                    ? (a, b) => (a.error < b.error) - (a.error > b.error)
                    : (a, b) => (a.error > b.error) - (a.error < b.error)
            ),
        ]);
    }, [sortDir]);

    return (
        <Dialog
            open
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                <ImportResultsHeader
                    correctItemsLength={correctFrameworkQuestionsLength}
                    showCorrectItems={showCorrectFrameworkQuestions}
                    setShowCorrectItems={setShowCorrectFrameworkQuestions}
                    sumTitle="Sum of correct framework question rows"
                />
                <Typography variant="h5">
                    ESG Framework questions for (
                    {allFrameworkQuestionData[0]?.frameworkName || 'Loading...'}):
                </Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Type</TableCell>
                                <TableCell align="left">Framework question id</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Question</TableCell>
                                <TableCell align="left">Fields</TableCell>
                                <TableCell align="left">Guidance</TableCell>
                                <SortableTableCell
                                    arrow={sort === 'result' ? sortDir : ''}
                                    onClick={() => setSorting('result')}
                                >
                                    Result
                                </SortableTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {showCorrectFrameworkQuestions
                                ? sortedFrameworkQuestions.map((row) => (
                                      <ImportFrameworkQuestionTableRow row={row} />
                                  ))
                                : sortedFrameworkQuestions
                                      .filter((row) => row.error)
                                      .map((row) => <ImportFrameworkQuestionTableRow row={row} />)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={closeAction}
                    autoFocus
                    label="Cancel"
                    buttonType="secondary"
                />
                <CommonButton
                    onClick={() => {
                        confirm();
                        showModal(MODAL_TYPE.NONE);
                    }}
                    label="Yes"
                    buttonType="primary"
                />
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationImportEsgFrameworkQuestionsModal;
