import {
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import SortableTableCell from '../../../components/atom/SortableTableCell';
import { TABLE_ROW_COUNT_OPTIONS } from '../../../helpers/constans';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';
import {
    deleteRowTitle,
    isRankVisible,
    navigateToEdit,
    navigateToTable,
    tooltipByTitle,
} from '../helpers/tableHelpers';

const EsgHierarchyTable = ({
    title,
    rows,
    limit,
    page,
    setParams,
    total,
    loading,
    sort,
    sortDir,
    setSorting,
    deleteRow,
}) => {
    const { showModal } = useModals();

    return (
        <Grid item xs={12}>
            <Table>
                <TableHead>
                    <TableRow>
                        <SortableTableCell
                            arrow={sort === 'name' ? sortDir : ''}
                            onClick={() => setSorting('name')}
                        >
                            Name
                        </SortableTableCell>
                        {isRankVisible(title) && (
                            <SortableTableCell
                                arrow={sort === 'rank' ? sortDir : ''}
                                onClick={() => setSorting('rank')}
                            >
                                Rank
                            </SortableTableCell>
                        )}
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <SkeletonTable rows={limit} cells={title === 'ESG Frameworks' ? 2 : 3} />
                    ) : (
                        rows?.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.name}</TableCell>
                                {isRankVisible(title) && <TableCell>{row.rank}</TableCell>}
                                <TableCell align="right">
                                    {title !== 'ESG Subsubcategories' && (
                                        <Link to={navigateToTable(title, row)}>
                                            <Tooltip title={tooltipByTitle(title)}>
                                                <IconButton>
                                                    <SubdirectoryArrowRightIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Link>
                                    )}
                                    <Link to={navigateToEdit(title, row)}>
                                        <Tooltip title="Edit">
                                            <IconButton>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Link>
                                    <IconButton
                                        onClick={() => {
                                            showModal(MODAL_TYPE.CONFIRMATION, {
                                                title: `Are you sure you want to delete this ${deleteRowTitle(
                                                    title
                                                )}?`,
                                                content:
                                                    'This action is irreversible and will delete all underlying levels.',
                                                confirm: () => deleteRow(row.id),
                                            });
                                        }}
                                    >
                                        <Tooltip title="Delete">
                                            <DeleteIcon />
                                        </Tooltip>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
            <TablePagination
                count={total || 0}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
            />
        </Grid>
    );
};

export default EsgHierarchyTable;
