import React, { useState } from 'react';
import {
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Dialog,
    Alert,
    TextField,
} from '@mui/material';
import CommonButton from '../components/atom/CommonButton';

const ConfirmationDeleteModal = ({
    title,
    content,
    confirm,
    warningContent = false,
    showModal,
    MODAL_TYPE,
}) => {
    const [inputValue, setInputValue] = useState('');
    const [isDeleteTyped, setIsDeleteTyped] = useState(false);

    const handleInputChange = (event) => {
        const { value } = event.target;
        setInputValue(value);
        setIsDeleteTyped(value.toLowerCase() === 'delete');
    };

    const closeAction = () => showModal(MODAL_TYPE.NONE);

    return (
        <Dialog
            open
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                {warningContent && (
                    <DialogContentText id="alert-dialog-warning">
                        <Alert sx={{ mt: 0.5 }} severity="warning">
                            {warningContent}
                        </Alert>
                    </DialogContentText>
                )}
                <TextField
                    sx={{ mt: 2 }}
                    fullWidth
                    autoComplete="off"
                    label="Type DELETE to confirm"
                    value={inputValue}
                    onChange={handleInputChange}
                />
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={closeAction}
                    autoFocus
                    label="Cancel"
                    buttonType="secondary"
                />
                <CommonButton
                    onClick={() => {
                        confirm();
                        showModal(MODAL_TYPE.NONE);
                    }}
                    label="Delete"
                    buttonType="primary"
                    disabled={!isDeleteTyped}
                />
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDeleteModal;
