export function navigateToTable(title, row) {
    switch (title) {
        case 'ESG Categories':
            return `/esgcategories/${row.id}/esgsubcategories`;
        case 'ESG Subcategories':
            return `/esgcategories/${row.esg_category_id}/esgsubcategories/${row.id}/esgsubsubcategories`;
        case 'ESG Frameworks':
            return `/esgframeworks/${row.id}/esgframeworkquestions`;
        default:
            return '/';
    }
}

export function navigateToEdit(title, row) {
    switch (title) {
        case 'ESG Categories':
            return `/esgcategories/edit/${row.id}`;
        case 'ESG Subcategories':
            return `/esgcategories/${row.esg_category_id}/esgsubcategories/edit/${row.id}`;
        case 'ESG Subsubcategories':
            return `/esgcategories/${row.esg_category_id}/esgsubcategories/${row.esg_subcategory_id}/esgsubsubcategories/edit/${row.id}`;
        case 'ESG Frameworks':
            return `/esgframeworks/edit/${row.id}`;
        default:
            return '/';
    }
}

export function tooltipByTitle(title) {
    switch (title) {
        case 'ESG Categories':
            return 'ESG Subcategories';
        case 'ESG Subcategories':
            return 'ESG Subsubcategories';
        case 'ESG Frameworks':
            return 'ESG Framework questions';
        default:
            return '';
    }
}

export function deleteRowTitle(title) {
    switch (title) {
        case 'ESG Categories':
            return 'ESG category';
        case 'ESG Subcategories':
            return 'ESG Subcategory';
        case 'ESG Subsubcategories':
            return 'ESG Subsubcategory';
        case 'ESG Frameworks':
            return 'ESG Framework';
        default:
            return '';
    }
}

export function isRankVisible(title) {
    return title !== 'ESG Frameworks';
}
