import React, { useEffect, useState } from 'react';
import { ButtonGroup, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import useSorting from '../../../hooks/useSorting';
import { doSwaggerCall, useApi } from '../../../hooks/useApi';
import handleImport from '../../../helpers/excelImportDatalinesHelpers';
import { datalineExport } from '../../../helpers/excelExportDatalinesHelpers';
import DatalinesTable from './DatalinesTable';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';
import useNotify from '../../../hooks/useNotify';
import { useAuth } from '../../../hooks/useAuth';
import { PERMISSIONS, TABLE_ROW_COUNT_DEFAULT } from '../../../helpers/constans';
import HelperIcon from '../../../components/atom/HelperIcon';
import InputButton from '../../../components/atom/InputButton';
import ButtonWithIcon from '../../../components/header/ButtonWithIcon';
import { DATALINE_GROUPS } from '../../../helpers/groups';
// import RequirementList from './RequirementList';

export default function DatalineSection({
    search,
    site,
    responsibility,
    sectionHeader,
    scope,
    category,
    subcategory,
    emissionSource,
    requirements,
    groupOpen,
    setGroupOpen,
    item,
    open,
    setOpen,
    groupby,
    edited,
    setEdited,
    triggerReload,
}) {
    const { notifyError, notifySuccess } = useNotify();
    const { year, closedYear } = useGlobalFilters();
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const { showModal } = useModals();
    const [selectedDatalines, setSelectedDatalines] = useState([]);
    const [selectMode, setSelectMode] = useState(false);
    const [generatingXlsx, setGeneratingXlsx] = useState(false);
    const [importingXlsx, setImportingXlsx] = useState(false);
    const { userPermissions, user } = useAuth();
    const manageDatalinePermission = userPermissions.includes(PERMISSIONS.MANAGE_DATALINES);
    const basicModifyDatalinePermission = userPermissions.includes(
        PERMISSIONS.BASIC_MODIFY_DATALINES
    );
    const complexModifyDatalinePermission = userPermissions.includes(
        PERMISSIONS.COMPLEX_MODIFY_DATALINES
    );
    const [systemManagedEmissionSource, setSystemManagedEmissionSource] = useState(false);
    const [data, loading, error, setData, reloadData] = useApi('Datalines', 'getDatalines', {
        year,
        scope,
        categoryId: category,
        subcategoryId: subcategory,
        emissionSourceId: emissionSource,
        limit,
        offset: page * limit,
        search,
        sort,
        sortDir,
        responsibility,
        siteIdList: site,
        datalineTagId: groupby === DATALINE_GROUPS.TAG ? item.id : undefined, // dataline tag id is passed only if groupby is TAG
    });

    useEffect(() => {
        setSystemManagedEmissionSource(data?.datalines[0]?.system_managed_emission_source);
    }, [data]);

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    useEffect(() => {
        setParams({ page: 0, limit });
    }, [year, site, scope, category, subcategory, emissionSource, search, responsibility]);

    const attachDocuments = async (idList) => {
        try {
            const res = await doSwaggerCall('Datalines', 'getDatalines', {
                idList,
                year,
            });
            showModal(MODAL_TYPE.ATTACH_DOCUMENTS_TO_DATALINES, {
                title: 'Attach documents to datalines',
                content:
                    'Upload documents then attach them with the attach button, once you are done click to finish import.',
                datalines: res.datalines,
                reloadData,
            });
        } catch (err) {
            notifyError(err);
        }
    };

    const handleImportDatalines = async (file, siteIdList) => {
        try {
            const emSource = await doSwaggerCall('EmissionSources', 'getEmissionSource', {
                id: emissionSource,
            });
            if (emSource.system_managed === 1) {
                throw new Error(
                    `Can't import datalines for system managed emission source ${emSource.name}`
                );
            }
            await handleImport(
                file,
                emissionSource,
                year,
                siteIdList,
                true,
                emSource.unit_conversion_logic
            ).then((res) => {
                showModal(MODAL_TYPE.CONFIRMATION_IMPORT_DATALINES, {
                    title: 'Are you sure you want to import datalines from this xlsx?',
                    content: 'This action is irreversible.',
                    data: res,
                    setImportingXlsx,
                    confirm: async () => {
                        const importedDatalines = await handleImport(
                            file,
                            emissionSource,
                            year,
                            siteIdList,
                            false,
                            emSource.unit_conversion_logic
                        );
                        notifySuccess('Import datalines successful');
                        setImportingXlsx(false);
                        reloadData();
                        if (importedDatalines?.idList?.length > 0) {
                            attachDocuments(importedDatalines?.idList);
                        }
                    },
                });
            });
        } catch (err) {
            setImportingXlsx(false);
            notifyError(err);
        }
    };

    const deleteDatalinesList = () => {
        showModal(MODAL_TYPE.CONFIRMATION, {
            title: 'Are you sure you want to remove datalines?',
            content: 'This action is irreversible.',
            confirm: () => {
                doSwaggerCall('Datalines', 'deleteDatalinesByList', {
                    idList: selectedDatalines.map((e) => e.id),
                })
                    .then(() => {
                        notifySuccess('Datalines deleted');
                        reloadData();
                        setSelectedDatalines([]);
                    })
                    .catch((err) => {
                        notifyError(err);
                    });
            },
        });
    };

    return (
        <>
            <Grid container item xs={12} justifyContent="space-between" spacing={0} sx={{ mt: 2 }}>
                <Grid item>
                    <Typography variant="h4">
                        {groupby === 'NONE' && responsibility === undefined ? '' : sectionHeader}
                        {item.guide_note && (
                            <HelperIcon title={item.name} guideNote={item.guide_note} />
                        )}
                        {(groupby === 'CATEGORY' && item.id !== 0) ||
                        groupby === 'SITE' ||
                        groupby === 'TAG' ? (
                            <IconButton
                                onClick={() => {
                                    groupOpen.filter((idx) => idx !== item.id);
                                    setOpen(!open);
                                }}
                            >
                                <KeyboardArrowUpIcon />
                            </IconButton>
                        ) : null}
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid
                        item
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item>
                            {emissionSource &&
                                manageDatalinePermission &&
                                !systemManagedEmissionSource && (
                                    <ButtonGroup color="primary">
                                        <ButtonWithIcon
                                            title="Add dataline"
                                            onClick={() => {
                                                if (closedYear) {
                                                    notifyError(
                                                        'Cannot add dataline to closed year.'
                                                    );
                                                    return;
                                                }
                                                setEdited(`new - ${emissionSource}`);
                                            }}
                                            icon={<AddIcon />}
                                        />
                                        <ButtonWithIcon
                                            title="Export to xlsx"
                                            onClick={async () => {
                                                setGeneratingXlsx(true);
                                                try {
                                                    await datalineExport(
                                                        emissionSource,
                                                        year,
                                                        site,
                                                        user?.company_id
                                                    );
                                                } catch (err) {
                                                    notifyError(err);
                                                }
                                                setGeneratingXlsx(false);
                                                notifySuccess('Datalines exported');
                                            }}
                                            icon={
                                                generatingXlsx ? (
                                                    <CircularProgress color="inherit" size={20} />
                                                ) : (
                                                    <FileDownloadIcon />
                                                )
                                            }
                                        />
                                        <InputButton
                                            id="dataline-list-upload"
                                            accept=".csv, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            onChange={(e) => {
                                                if (e.target.files.length === 0) {
                                                    return;
                                                }
                                                if (closedYear) {
                                                    notifyError(
                                                        'Cannot import datalines to closed year.'
                                                    );
                                                    return;
                                                }
                                                setImportingXlsx(true);
                                                handleImportDatalines(
                                                    e.target.files[0],
                                                    site
                                                ).catch((err) => {
                                                    notifyError(err);
                                                });
                                                e.target.value = '';
                                            }}
                                            type="file"
                                            title="Import from xlsx"
                                            loading={importingXlsx}
                                        />
                                    </ButtonGroup>
                                )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid item xs={12}>
                <RequirementList requirements={requirements} />
            </Grid> */}
            <DatalinesTable
                rows={data?.datalines}
                limit={limit}
                page={page}
                setParams={setParams}
                total={data?.total}
                loading={loading}
                reloadData={reloadData}
                sort={sort}
                sites={site}
                sortDir={sortDir}
                setSorting={setSorting}
                responsibility={responsibility}
                edited={edited}
                setEdited={setEdited}
                selectMode={selectMode}
                setSelectMode={setSelectMode}
                selectedDatalines={selectedDatalines}
                setSelectedDatalines={setSelectedDatalines}
                deleteDatalinesList={deleteDatalinesList}
                emissionSourceId={emissionSource}
                manageDatalinePermission={manageDatalinePermission}
                basicModifyDatalinePermission={basicModifyDatalinePermission}
                complexModifyDatalinePermission={complexModifyDatalinePermission}
                closedYear={closedYear}
                setGroupOpen={setGroupOpen}
                triggerReload={triggerReload}
            />
        </>
    );
}
