import { Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import React from 'react';
import AutocompleteSelect from '../../../components/atom/AutoCompleteSelect';
import {
    sourceAutocomplete,
    sourceWithoutLimitationAutocomplete,
    subcategoryAutocomplete,
    subcategoryWithoutLimitationAutocomplete,
} from '../../../autocomplete';
import SkeletonForm from '../../../components/atom/SkeletonForm';
import BlockWithTitle from '../../../components/atom/BlockWithTitle';
import { MULTIVARIABLE_EMISSION_SOURCES } from '../../../config';
import CommonButton from '../../../components/atom/CommonButton';
import { groupEmissionSource } from '../../../helpers/groups';

function groupSubcategoryAndCategoryAndScope(item) {
    return `${item?.scope_name} - ${item?.category_name} - ${item?.name}`;
}

const EmissionSourceForm = ({ loading, values, setFieldValue, touched, isSubmitting, errors }) => {
    return (
        <BlockWithTitle title="Edit Emission Source">
            <Grid item xs={12}>
                <Grid container>
                    {loading ? (
                        <SkeletonForm rows={6} />
                    ) : (
                        <>
                            {MULTIVARIABLE_EMISSION_SOURCES && (
                                <Grid item xs={12}>
                                    <AutocompleteSelect
                                        grouping={groupEmissionSource}
                                        initCall={sourceWithoutLimitationAutocomplete.init}
                                        autocompleteCall={sourceAutocomplete.search}
                                        value={values.parent_id}
                                        setValue={(v) => {
                                            setFieldValue('parent_id', v);
                                        }}
                                    >
                                        <TextField label="Parent emission source" />
                                    </AutocompleteSelect>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <TextField
                                    label="Name"
                                    autoComplete="off"
                                    fullWidth
                                    value={values?.name}
                                    onChange={(ev) => setFieldValue('name', ev.target.value)}
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name ? errors.name : ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AutocompleteSelect
                                    grouping={groupSubcategoryAndCategoryAndScope}
                                    initCall={subcategoryWithoutLimitationAutocomplete.init}
                                    disableClearable
                                    autocompleteCall={subcategoryAutocomplete.search}
                                    value={`${values?.subcategory_id}`}
                                    setValue={(ev) => setFieldValue('subcategory_id', ev)}
                                >
                                    <TextField label="Subcategory" required />
                                </AutocompleteSelect>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Base unit name"
                                    fullWidth
                                    value={values?.base_unit}
                                    onChange={(ev) => setFieldValue('base_unit', ev.target.value)}
                                    disabled={values?.existing_template_connection}
                                    error={touched.base_unit && Boolean(errors.base_unit)}
                                    helperText={touched.base_unit ? errors.base_unit : ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="logic-select">
                                        Default calculation logic
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="logic-select"
                                        id="demo-simple-select"
                                        value={values?.default_calculation_logic || 0}
                                        label="Default calculation logic"
                                        onChange={(e) => {
                                            setFieldValue(
                                                'default_calculation_logic',
                                                e.target.value
                                            );
                                        }}
                                    >
                                        <MenuItem value={0}>
                                            {values.base_unit} (base unit)
                                        </MenuItem>
                                        {(values?.unit_conversion_logic || []).map((logic, idx) => {
                                            return (
                                                <MenuItem key={idx} value={logic.id}>
                                                    {logic.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Note in report (optional)"
                                    fullWidth
                                    autoComplete="off"
                                    multiline
                                    value={values?.source}
                                    onChange={(ev) => setFieldValue('source', ev.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Admin note (optional)"
                                    fullWidth
                                    autoComplete="off"
                                    multiline
                                    value={values?.admin_note}
                                    onChange={(ev) => setFieldValue('admin_note', ev.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Note for guidance (optional)"
                                    fullWidth
                                    autoComplete="off"
                                    multiline
                                    value={values?.guide_note}
                                    onChange={(ev) => setFieldValue('guide_note', ev.target.value)}
                                />
                            </Grid>
                            {!values?.system_managed && (
                                <Grid item xs={12} md={4}>
                                    <CommonButton
                                        type="submit"
                                        disabled={isSubmitting}
                                        fullWidth
                                        label="Save"
                                        buttonType="primary"
                                    />
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            </Grid>
        </BlockWithTitle>
    );
};

export default EmissionSourceForm;
