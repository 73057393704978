import React, { useCallback, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Tooltip,
} from '@mui/material';
import { useEsgFilters } from '../hooks/useEsgFilters';
import AddEsgForm from './components/esg/AddEsgForm';
import {
    esgCategoryAutocomplete,
    esgDisclosureAutocomplete,
    esgQuestionAutocomplete,
    esgSubcategoryAutocomplete,
} from '../autocomplete';
import AutocompleteSelect from '../components/atom/AutoCompleteSelect';
import CommonButton from '../components/atom/CommonButton';
import { groupDisclosure, groupEsgSubcategory, groupQuestion } from '../helpers/groups';

const EsgFilterModal = ({ title, showModal, MODAL_TYPE, setGroupOpen, setGroupby }) => {
    const { setEdited } = useEsgFilters();
    const [filterStep, setFilterStep] = useState(true);
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const [esgCategory, setEsgCategory] = useState(null);
    const [esgCategoryName, setEsgCategoryName] = useState(null);
    const [esgSubcategory, setEsgSubcategory] = useState(null);
    const [esgSubcategoryName, setEsgSubcategoryName] = useState(null);
    const [esgDisclosure, setEsgDisclosure] = useState(null);
    const [esgDisclosureName, setEsgDisclosureName] = useState(null);
    const [esgQuestion, setEsgQuestion] = useState(null);
    const [esgQuestionName, setEsgQuestionName] = useState(null);
    const setLocalEsgFilters = useCallback(
        ({
            esgCategoryFilter,
            esgCategoryNameFilter,
            esgSubcategoryFilter,
            esgSubcategoryNameFilter,
            esgDisclosureFilter,
            esgDisclosureNameFilter,
            esgQuestionFilter,
            esgQuestionNameFilter,
        }) => {
            if (typeof esgCategoryFilter !== 'undefined') {
                setEsgCategory(esgCategoryFilter);
                setEsgCategoryName(esgCategoryNameFilter);
            }
            if (typeof esgSubcategoryFilter !== 'undefined') {
                setEsgSubcategory(esgSubcategoryFilter);
                setEsgSubcategoryName(esgSubcategoryNameFilter);
            }
            if (typeof esgDisclosureFilter !== 'undefined') {
                setEsgDisclosure(esgDisclosureFilter);
                setEsgDisclosureName(esgDisclosureNameFilter);
            }
            if (typeof esgQuestionFilter !== 'undefined') {
                setEsgQuestion(esgQuestionFilter);
                setEsgQuestionName(esgQuestionNameFilter);
            }
        },
        [setEsgCategory, setEsgSubcategory, setEsgDisclosure, setEsgQuestion]
    );

    const handleClear = () => {
        setLocalEsgFilters({
            esgCategoryFilter: null,
            esgSubcategoryFilter: null,
            esgDisclosureFilter: null,
            esgQuestionFilter: null,
        });
        setEdited(false);
    };

    return (
        <Dialog open onClose={closeAction} maxWidth="md" fullWidth>
            <DialogTitle sx={{ alignSelf: 'center' }}>{title}</DialogTitle>
            <DialogContent>
                {filterStep ? (
                    <Grid container spacing={2} sx={{ pt: 1 }}>
                        <Grid item xs={12}>
                            <AutocompleteSelect
                                initCall={esgCategoryAutocomplete.init}
                                autocompleteCall={esgCategoryAutocomplete.search}
                                value={esgCategory}
                                setValue={(ev, getItem) => {
                                    const item = getItem(ev);
                                    setLocalEsgFilters({
                                        esgCategoryFilter: item?.id || null,
                                        esgCategoryNameFilter: item?.name || null,
                                        esgSubcategoryFilter: null,
                                        esgDisclosureFilter: null,
                                        esgQuestionFilter: null,
                                    });
                                    setEdited(false);
                                }}
                            >
                                <TextField label="ESG Category" fullWidth size="small" />
                            </AutocompleteSelect>
                        </Grid>
                        <Grid item xs={12}>
                            <AutocompleteSelect
                                grouping={groupEsgSubcategory}
                                initCall={esgSubcategoryAutocomplete.init}
                                autocompleteCall={esgSubcategoryAutocomplete.search}
                                value={esgSubcategory}
                                setValue={(ev, getItem) => {
                                    const item = getItem(ev);
                                    setLocalEsgFilters({
                                        esgCategoryFilter: item?.category_id,
                                        esgCategoryNameFilter: item?.category_name || null,
                                        esgSubcategoryFilter: item?.id || null,
                                        esgSubcategoryNameFilter: item?.name || null,
                                        esgDisclosureFilter: null,
                                        esgQuestionFilter: null,
                                    });
                                    setEdited(false);
                                }}
                                searchParams={{
                                    esgCategoryId: esgCategory,
                                }}
                            >
                                <TextField label="ESG Subcategory" fullWidth size="small" />
                            </AutocompleteSelect>
                        </Grid>
                        <Grid item xs={12}>
                            <AutocompleteSelect
                                grouping={groupDisclosure}
                                initCall={esgDisclosureAutocomplete.init}
                                autocompleteCall={esgDisclosureAutocomplete.search}
                                value={esgDisclosure}
                                setValue={(ev, getItem) => {
                                    const item = getItem(ev);
                                    setLocalEsgFilters({
                                        esgCategoryFilter: item?.category_id,
                                        esgCategoryNameFilter: item?.category_name || null,
                                        esgSubcategoryFilter: item?.subcategory_id,
                                        esgSubcategoryNameFilter: item?.subcategory_name || null,
                                        esgDisclosureFilter: item?.id || null,
                                        esgDisclosureNameFilter: item?.name || null,
                                        esgQuestionFilter: null,
                                    });
                                    setEdited(false);
                                }}
                                searchParams={{
                                    esgCategoryId: esgCategory,
                                    esgSubcategoryId: esgSubcategory,
                                }}
                            >
                                <TextField label="ESG Subsubcategory" fullWidth size="small" />
                            </AutocompleteSelect>
                        </Grid>
                        <Grid item xs={12}>
                            <AutocompleteSelect
                                grouping={groupQuestion}
                                initCall={esgQuestionAutocomplete.init}
                                autocompleteCall={esgQuestionAutocomplete.search}
                                value={esgQuestion}
                                setValue={(ev, getItem) => {
                                    const item = getItem(ev);
                                    setLocalEsgFilters({
                                        esgCategoryFilter: item?.category_id,
                                        esgCategoryNameFilter: item?.category_name || null,
                                        esgSubcategoryFilter: item?.subcategory_id,
                                        esgSubcategoryNameFilter: item?.subcategory_name || null,
                                        esgDisclosureFilter: item?.disclosure_id,
                                        esgDisclosureNameFilter: item?.disclosure_name || null,
                                        esgQuestionFilter: item?.id || null,
                                        esgQuestionNameFilter: item?.name || null,
                                    });
                                    setEdited(false);
                                }}
                                searchParams={{
                                    esgCategoryId: esgCategory,
                                    esgSubcategoryId: esgSubcategory,
                                    esgDisclosureId: esgDisclosure,
                                }}
                            >
                                <TextField label="ESG Question" fullWidth size="small" />
                            </AutocompleteSelect>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={2} sx={{ pt: 1 }}>
                        <AddEsgForm
                            closeAction={closeAction}
                            setFilterStep={setFilterStep}
                            esgCategoryName={esgCategoryName}
                            esgCategoryId={esgCategory}
                            esgSubcategoryName={esgSubcategoryName}
                            esgSubcategoryId={esgSubcategory}
                            esgDisclosureName={esgDisclosureName}
                            esgDisclosureId={esgDisclosure}
                            esgQuestionName={esgQuestionName}
                            esgQuestionId={esgQuestion}
                            setGroupOpen={setGroupOpen}
                            setGroupby={setGroupby}
                        />
                    </Grid>
                )}
            </DialogContent>
            {filterStep && (
                <DialogActions>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <CommonButton
                                onClick={handleClear}
                                fullWidth
                                label="Clear"
                                buttonType="secondary"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            {!esgQuestion || esgQuestion === '' || esgQuestion === null ? (
                                <Tooltip title="Select a question first!">
                                    <span>
                                        <CommonButton
                                            onClick={() => {}}
                                            disabled
                                            fullWidth
                                            label="Enter Data"
                                            buttonType="primary"
                                        />
                                    </span>
                                </Tooltip>
                            ) : (
                                <CommonButton
                                    onClick={() => {
                                        setFilterStep(false);
                                    }}
                                    fullWidth
                                    label="Enter Data"
                                    buttonType="primary"
                                />
                            )}
                        </Grid>
                    </Grid>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default EsgFilterModal;
