import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useApi } from '../hooks/useApi';
import useNotify from '../hooks/useNotify';
import CommonButton from '../components/atom/CommonButton';
import AutocompleteSelect from '../components/atom/AutoCompleteSelect';
import {
    esgCategoryAutocomplete,
    esgDisclosureAutocomplete,
    esgQuestionAutocomplete,
    esgSubcategoryAutocomplete,
} from '../autocomplete';
import { groupDisclosure, groupEsgSubcategory, groupQuestion } from '../helpers/groups';
import LocalSiteFilter from '../pages/report/components/LocalSiteFilter';
import { useAuth } from '../hooks/useAuth';
import { useGlobalFilters } from '../hooks/useGlobalFilters';

function SelectEsgReportModal({ showModal, MODAL_TYPE, confirm }) {
    const { user } = useAuth();
    const { availableYears } = useGlobalFilters();
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const reportTypes = ['XLSX', 'PDF', 'HTML'];
    const [reportType, setReportType] = useState(reportTypes[0]);
    const [selectTag, setSelectTag] = useState(false);
    const [siteIdList, setSiteIdList] = useState([]);
    const [reportTagId, setReportTagId] = useState(null);
    const [selectFramework, setSelectFramework] = useState(false);
    const [frameworkId, setFrameworkId] = useState(null);
    const [frameworks, loading, error] = useApi('EsgFrameworks', 'getAllEsgFrameworks', {
        pageSize: 1000,
    });
    const [selectedEsgCategory, setSelectedEsgCategory] = useState(null);
    const [selectedEsgSubcategory, setSelectedEsgSubcategory] = useState(null);
    const [selectedEsgDisclosure, setSelectedEsgDisclosures] = useState(null);
    const [selectedEsgQuestion, setSelectedEsgQuestion] = useState(null);
    const [year, setYear] = useState(
        parseInt(localStorage.getItem('year'), 10) || new Date().getFullYear() - 1
    );
    const [auditReportFormat, setAuditReportFormat] = useState(false);
    const { notifyError } = useNotify();
    const [esgTags, esgLoading, esgError] = useApi('EsgTags', 'getAllEsgTags');

    useEffect(() => {
        if (error || esgError) {
            notifyError('Something went wrong');
        }
    }, [error, esgError, notifyError]);

    return (
        <Dialog
            aria-labelledby="select-framework-modal"
            open
            onClose={closeAction}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>Generate ESG Report</DialogTitle>
            <Grid>
                <DialogContent>
                    <Grid item xs={12} sx={{ pt: 1 }}>
                        <Accordion defaultExpanded={false} sx={{ border: '1px solid grey' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <InputLabel>Source filter</InputLabel>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid item xs={12}>
                                    <AutocompleteSelect
                                        initCall={esgCategoryAutocomplete.init}
                                        autocompleteCall={esgCategoryAutocomplete.search}
                                        value={selectedEsgCategory}
                                        setValue={(ev, getItem) => {
                                            const item = getItem(ev);
                                            if (!item) {
                                                setSelectedEsgCategory(null);
                                                setSelectedEsgSubcategory(null);
                                                setSelectedEsgDisclosures(null);
                                                setSelectedEsgQuestion(null);
                                            } else {
                                                setSelectedEsgCategory(item?.id || null);
                                            }
                                        }}
                                    >
                                        <TextField label="ESG Category" fullWidth size="small" />
                                    </AutocompleteSelect>
                                </Grid>
                                <Grid item xs={12} sx={{ pt: 1 }}>
                                    <AutocompleteSelect
                                        grouping={groupEsgSubcategory}
                                        initCall={esgSubcategoryAutocomplete.init}
                                        autocompleteCall={esgSubcategoryAutocomplete.search}
                                        value={selectedEsgSubcategory}
                                        setValue={(ev, getItem) => {
                                            const item = getItem(ev);
                                            if (!item) {
                                                setSelectedEsgSubcategory(null);
                                                setSelectedEsgDisclosures(null);
                                                setSelectedEsgQuestion(null);
                                            } else {
                                                setSelectedEsgCategory(item?.category_id || null);
                                                setSelectedEsgSubcategory(item?.id || null);
                                            }
                                        }}
                                        searchParams={{
                                            esgCategoryId: selectedEsgCategory,
                                        }}
                                    >
                                        <TextField label="ESG Subcategory" fullWidth size="small" />
                                    </AutocompleteSelect>
                                </Grid>
                                <Grid item xs={12} sx={{ pt: 1 }}>
                                    <AutocompleteSelect
                                        grouping={groupDisclosure}
                                        initCall={esgDisclosureAutocomplete.init}
                                        autocompleteCall={esgDisclosureAutocomplete.search}
                                        value={selectedEsgDisclosure}
                                        setValue={(ev, getItem) => {
                                            const item = getItem(ev);
                                            if (!item) {
                                                setSelectedEsgDisclosures(null);
                                                setSelectedEsgQuestion(null);
                                            } else {
                                                setSelectedEsgCategory(item?.category_id || null);
                                                setSelectedEsgSubcategory(
                                                    item?.subcategory_id || null
                                                );
                                                setSelectedEsgDisclosures(item?.id || null);
                                            }
                                        }}
                                        searchParams={{
                                            esgCategoryId: selectedEsgCategory,
                                            esgSubcategoryId: selectedEsgSubcategory,
                                        }}
                                    >
                                        <TextField
                                            label="ESG Subsubcategory"
                                            fullWidth
                                            size="small"
                                        />
                                    </AutocompleteSelect>
                                </Grid>
                                <Grid item xs={12} sx={{ pt: 1 }}>
                                    <AutocompleteSelect
                                        grouping={groupQuestion}
                                        initCall={esgQuestionAutocomplete.init}
                                        autocompleteCall={esgQuestionAutocomplete.search}
                                        value={selectedEsgQuestion}
                                        setValue={(ev, getItem) => {
                                            const item = getItem(ev);
                                            if (!item) {
                                                setSelectedEsgQuestion(null);
                                            } else {
                                                setSelectedEsgCategory(item?.category_id || null);
                                                setSelectedEsgSubcategory(
                                                    item?.subcategory_id || null
                                                );
                                                setSelectedEsgDisclosures(
                                                    item?.disclosure_id || null
                                                );
                                                setSelectedEsgQuestion(item?.id || null);
                                            }
                                        }}
                                        searchParams={{
                                            esgCategoryId: selectedEsgCategory,
                                            esgSubcategoryId: selectedEsgSubcategory,
                                            esgDisclosureId: selectedEsgDisclosure,
                                            year,
                                        }}
                                    >
                                        <TextField label="ESG Question" fullWidth size="small" />
                                    </AutocompleteSelect>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12} sx={{ pt: 1 }}>
                        <Accordion defaultExpanded={false} sx={{ border: '1px solid grey' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <InputLabel>Site filter</InputLabel>
                            </AccordionSummary>
                            <AccordionDetails>
                                <LocalSiteFilter
                                    siteIdList={siteIdList}
                                    setSiteIdList={setSiteIdList}
                                    companyId={user.company_id}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12} sx={{ pt: 1 }}>
                        <FormControl fullWidth>
                            <InputLabel id="year-select">Year</InputLabel>
                            <Select
                                fullWidth
                                labelId="year-select"
                                id="demo-simple-select"
                                value={year}
                                size="small"
                                label="Year"
                                onChange={(e) => {
                                    setYear(parseInt(e.target.value, 10));
                                }}
                            >
                                {availableYears.map((yearItem) => (
                                    <MenuItem key={yearItem.year} value={yearItem.year}>
                                        {yearItem.year}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{ pt: 1 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectFramework}
                                    onChange={(e) => {
                                        if (selectFramework) {
                                            setFrameworkId(null);
                                        }
                                        setSelectFramework(!!e.target.checked);
                                    }}
                                />
                            }
                            label="Select a framework"
                            labelPlacement="end"
                        />
                    </Grid>
                    {selectFramework && (
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="framework">ESG Framework</InputLabel>
                                {!loading ? (
                                    <Select
                                        fullWidth
                                        labelId="framework"
                                        size="small"
                                        label="Filter by"
                                        value={frameworkId || ''}
                                        onChange={(e) => {
                                            setFrameworkId(e.target.value);
                                        }}
                                    >
                                        {frameworks?.frameworks.map((framework) => {
                                            return (
                                                <MenuItem value={framework.id} key={framework.id}>
                                                    {framework.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                ) : (
                                    <CircularProgress size="1.5rem" />
                                )}
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={12} sx={{ pt: 1 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectTag}
                                    onChange={(e) => {
                                        if (selectTag) {
                                            setReportTagId(null);
                                        }
                                        setSelectTag(!!e.target.checked);
                                    }}
                                />
                            }
                            label="Select a tag"
                            labelPlacement="end"
                        />
                    </Grid>
                    {selectTag && (
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="esg-tag">ESG tag</InputLabel>
                                {!esgLoading ? (
                                    <Select
                                        fullWidth
                                        labelId="esg-tag"
                                        size="small"
                                        label="Filter by"
                                        value={reportTagId || ''}
                                        onChange={(e) => {
                                            setReportTagId(e.target.value);
                                        }}
                                    >
                                        {esgTags?.esgTags.map((esgTag) => {
                                            return (
                                                <MenuItem value={esgTag.id} key={esgTag.id}>
                                                    {esgTag.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                ) : (
                                    <CircularProgress size="1.5rem" />
                                )}
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={12} sx={{ pt: 1 }}>
                        <FormControl fullWidth>
                            <InputLabel id="report-type">Report type</InputLabel>
                            {!loading ? (
                                <Select
                                    fullWidth
                                    labelId="report-type"
                                    size="small"
                                    label="Report type"
                                    value={reportType || ''}
                                    onChange={(e) => {
                                        setReportType(e.target.value);
                                    }}
                                >
                                    {reportTypes.map((type) => {
                                        return (
                                            <MenuItem value={type} key={type}>
                                                {type}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            ) : (
                                <CircularProgress size="1.5rem" />
                            )}
                        </FormControl>
                    </Grid>
                    {reportType !== 'XLSX' ? (
                        <Grid item xs={12} sx={{ pt: 2 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={auditReportFormat}
                                        onChange={(e) => {
                                            setAuditReportFormat(!!e.target.checked);
                                        }}
                                    />
                                }
                                label="Audit report format"
                                labelPlacement="end"
                            />
                        </Grid>
                    ) : null}
                </DialogContent>
            </Grid>
            <DialogActions>
                <CommonButton
                    onClick={() => showModal(MODAL_TYPE.NONE)}
                    label="Close"
                    buttonType="secondary"
                />
                <CommonButton
                    onClick={() => {
                        confirm(
                            frameworkId,
                            reportTagId,
                            siteIdList,
                            reportType,
                            selectedEsgCategory,
                            selectedEsgSubcategory,
                            selectedEsgDisclosure,
                            selectedEsgQuestion,
                            year,
                            auditReportFormat
                        );
                        showModal(MODAL_TYPE.NONE);
                    }}
                    type="submit"
                    label="Save"
                    buttonType="primary"
                />
            </DialogActions>
        </Dialog>
    );
}

export default SelectEsgReportModal;
