import {
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Add from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CommonButton from '../../../components/atom/CommonButton';
import useNotify from '../../../hooks/useNotify';
import validateFormat from '../../emissionsourceedit/helpers/validateFormat';

const Conversion = ({ idx, values, setFieldValue, removeConversionLogic }) => {
    const [fieldId, setFieldId] = useState('');
    const [fieldLabel, setFieldLabel] = useState('');
    const { notifyError, notifySuccess } = useNotify();

    const onDeleteField = (fieldIdx) => {
        const editedFields = values.conversionLogics[idx].fields;
        editedFields.splice(fieldIdx, 1);
        return editedFields;
    };

    const onAddField = () => {
        const newField = {
            field_id: fieldId,
            label: fieldLabel,
        };
        const editedFields = [...values.conversionLogics[idx].fields, newField];
        return editedFields;
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    required
                    autoComplete="off"
                    size="medium"
                    label="Conversion logic name"
                    value={values.conversionLogics[idx].name}
                    onChange={(ev) =>
                        setFieldValue(`conversionLogics[${idx}].name`, ev.target.value)
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    autoComplete="off"
                    label="Conversion logic source (optional)"
                    multiline
                    value={values.conversionLogics[idx].source || ''}
                    onChange={(ev) =>
                        setFieldValue(`conversionLogics[${idx}].source`, ev.target.value)
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Admin note (optional)"
                    fullWidth
                    autoComplete="off"
                    multiline
                    value={values.conversionLogics[idx].admin_note || ''}
                    onChange={(ev) =>
                        setFieldValue(`conversionLogics[${idx}].admin_note`, ev.target.value)
                    }
                />
            </Grid>
            <Grid item xs={11}>
                <TextField
                    fullWidth
                    required
                    autoComplete="off"
                    label="Formula"
                    value={values.conversionLogics[idx].formula}
                    onChange={(ev) =>
                        setFieldValue(`conversionLogics[${idx}].formula`, ev.target.value)
                    }
                />
            </Grid>
            <Grid item xs={1}>
                <Tooltip title="Validate format" placement="top">
                    <IconButton
                        fullWidth
                        onClick={() =>
                            validateFormat(values.conversionLogics[idx], notifyError, notifySuccess)
                        }
                    >
                        <FactCheckIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Fields</Typography>
            </Grid>
            <Grid item xs={12}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Field ID</TableCell>
                            <TableCell>Label</TableCell>
                            <TableCell>Prefix</TableCell>
                            <TableCell>Postfix</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {values.conversionLogics[idx]?.fields?.map((field, fieldIdx) => (
                            <TableRow>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        autoComplete="off"
                                        value={field.field_id}
                                        onChange={(ev) =>
                                            setFieldValue(
                                                `conversionLogics[${idx}].fields[${fieldIdx}].field_id`,
                                                ev.target.value
                                            )
                                        }
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        autoComplete="off"
                                        value={field.label}
                                        onChange={(ev) =>
                                            setFieldValue(
                                                `conversionLogics[${idx}].fields[${fieldIdx}].label`,
                                                ev.target.value
                                            )
                                        }
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        autoComplete="off"
                                        value={field.prefix}
                                        onChange={(ev) =>
                                            setFieldValue(
                                                `conversionLogics[${idx}].fields[${fieldIdx}].prefix`,
                                                ev.target.value
                                            )
                                        }
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        autoComplete="off"
                                        value={field.postfix}
                                        onChange={(ev) =>
                                            setFieldValue(
                                                `conversionLogics[${idx}].fields[${fieldIdx}].postfix`,
                                                ev.target.value
                                            )
                                        }
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={() =>
                                            setFieldValue(
                                                `conversionLogics[${idx}].fields`,
                                                onDeleteField(fieldIdx)
                                            )
                                        }
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell colSpan={4} />
                            <TableCell>
                                <IconButton
                                    onClick={() => {
                                        setFieldValue(
                                            `conversionLogics[${idx}].fields`,
                                            onAddField()
                                        );
                                        setFieldId('');
                                        setFieldLabel('');
                                    }}
                                >
                                    <Add />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={12} md={12}>
                <CommonButton
                    fullWidth
                    onClick={async () => {
                        removeConversionLogic(values, idx, setFieldValue);
                    }}
                    label="Delete logic"
                    buttonType="secondary"
                />
            </Grid>
        </Grid>
    );
};

export default Conversion;
