import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';
import { jsonConstants } from './esgUnitConversions';
import { setComment } from './excelExportHelpers';

// Helper function to shorten sheet name if it exceeds 31 characters (Excel limit)
function getShortenedSheetName(name) {
    const maxLength = 31;
    return name.length > maxLength ? `${name.slice(0, maxLength - 3)}...` : name;
}

const ESG_FRAMEWORK_QUESTIONS_HEADER_MAPPING = {
    id: 'Framework question id',
    standard: 'Standard',
    disclosure: 'Disclosure',
    marked_for_deletion: 'Marked for deletion',
    esg_disclosure_id: 'Subsubcategory id',
    question_id: 'Question id',
    question: 'Question',
    guidance: 'Guidance',
    numeric_field_label1: 'Numeric field label',
    default_measure_numeric_value1: 'Default measure numeric value 1',
    numeric_field_label2: 'Numeric field 2 label',
    default_measure_numeric_value2: 'Default measure numeric value 2',
    numeric_field_label3: 'Numeric field 3 label',
    default_measure_numeric_value3: 'Default measure numeric value 3',
    numeric_field_label4: 'Numeric field 4 label',
    default_measure_numeric_value4: 'Default measure numeric value 4',
    numeric_field_label5: 'Numeric field 5 label',
    default_measure_numeric_value5: 'Default measure numeric value 5',
    numeric_field_label6: 'Numeric field 6 label',
    default_measure_numeric_value6: 'Default measure numeric value 6',
    text_field_label1: 'Text field label',
    text_field_label2: 'Text field 2 label',
    text_field_label3: 'Text field 3 label',
    text_field_label4: 'Text field 4 label',
    text_field_label5: 'Text field 5 label',
    text_field_label6: 'Text field 6 label',
    select_field_label1: 'Yes/No field label',
    select_field_label2: 'Yes/No field 2 label',
    select_field_label3: 'Yes/No field 3 label',
    select_field_label4: 'Yes/No field 4 label',
    select_field_label5: 'Yes/No field 5 label',
    select_field_label6: 'Yes/No field 6 label',
    select_field_label7: 'Yes/No field 7 label',
    select_field_label8: 'Yes/No field 8 label',
};

const ESG_HELPER_TAB_HEADER_MAPPING = {
    id: 'Id',
    name: 'Name',
    subcategory_name: 'Subcategory name',
    category_name: 'Category name',
};

/**
 * Generate xlsx esg data from an array of objects
 * @param {array} data array of objects to convert to xlsx rows
 * @returns header fields from object keys and rows from values
 */
export const generateXlsxEsgFrameworkQuestionsData = (
    data,
    secondUnitsTab = false,
    thirdDisclosuresTab = false
) => {
    // blank sheet for frameworks
    if (!data || data.length === 0) {
        return [Object.values(ESG_FRAMEWORK_QUESTIONS_HEADER_MAPPING)];
    }

    const newData = data;
    const headers = Object.keys(newData[0]).map((object) => {
        if (secondUnitsTab) {
            return object;
        }
        if (thirdDisclosuresTab) {
            return ESG_HELPER_TAB_HEADER_MAPPING[object];
        }
        return ESG_FRAMEWORK_QUESTIONS_HEADER_MAPPING[object];
    });
    const rows = newData.map((element) => {
        return Object.values(element).map((value) => {
            if (Array.isArray(value)) {
                return value.map((e) => e.name).join('\n');
            }
            return value;
        });
    });

    return [headers, ...rows];
};

/**
 * Generate xlsx file and initiate download
 * @param {array} data array of objects to convert to xlsx rows
 */
export const exportToXlsxEsgFrameworkQuestion = (data, name, allEsgDisclosures) => {
    const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    // sheet for esg conversion options (name, type)
    const esgUnitConversions = jsonConstants.map((unit) => ({
        name: unit.name,
        type: unit.type,
    }));

    // define sheets
    const ws = utils.aoa_to_sheet(generateXlsxEsgFrameworkQuestionsData(data));
    const ws2 = utils.aoa_to_sheet(generateXlsxEsgFrameworkQuestionsData(esgUnitConversions, true));
    const ws3 = utils.aoa_to_sheet(
        generateXlsxEsgFrameworkQuestionsData(allEsgDisclosures, false, true)
    );

    // set comments and helper texts
    setComment(
        ws,
        'D1',
        'This only deletes framework questions, not the ESG question itself or the framework.'
    );
    setComment(
        ws,
        'E1',
        'Use the subsubcategory id from the Subsubcategories tab to optionally link the question to a subsubcategory.'
    );
    utils.sheet_add_aoa(
        ws2,
        [['This is a helper tab to list all available ESG unit conversions.']],
        {
            origin: 'D1',
        }
    );
    utils.sheet_add_aoa(
        ws3,
        [['This is a helper tab to list all available ESG Subsubcategories.']],
        {
            origin: 'F1',
        }
    );

    const wb = utils.book_new();
    const sheetName = getShortenedSheetName(`${data[0]?.framework_name || 'Framework'} Questions`);
    utils.book_append_sheet(wb, ws, sheetName);
    utils.book_append_sheet(wb, ws2, 'Unit conversions');
    utils.book_append_sheet(wb, ws3, 'Subsubcategories');
    const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });

    const file = new Blob([excelBuffer], { type: fileType });
    saveAs(file, `${name}${fileExtension}`);
};
